import React from "react";
import _ from "lodash";
import moment from "moment-strftime";

import { Layout } from "../components/index";
import Header from "../components/Header";
import { getPages, Link, safePrefix } from "../utils";
import Footer from "../components/Footer";

export default class Home extends React.Component {
  render() {
    let display_posts = _.orderBy(
      getPages(this.props.pageContext.pages, "/posts"),
      "frontmatter.date",
      "desc"
    );
    return (
      <Layout {...this.props}>
        <Header
          {...this.props}
          site={this.props.pageContext.site}
          page={this.props.pageContext}
          img_path={_.get(
            this.props,
            "pageContext.site.siteMetadata.header.bg_img"
          )}
        />
        <div id="content" className="site-content">
          <main id="main" className="site-main inner">
            <div className="post-feed">
              {_.map(display_posts, (post, post_idx) => (
                <article key={post_idx} className="post">
                  <header className="post-header">
                    <h2 className="post-title">
                      <Link to={safePrefix(_.get(post, "url"))} rel="bookmark">
                        {post.frontmatter.title}
                      </Link>
                    </h2>
                    <div className="post-meta">
                      Published on{" "}
                      <time
                        className="published"
                        datetime={moment(
                          _.get(post, "frontmatter.date")
                        ).strftime("%Y-%m-%d %H:%M")}
                      >
                        {moment(_.get(post, "frontmatter.date")).strftime(
                          "%B %d, %Y"
                        )}
                      </time>
                    </div>
                  </header>
                  {post.frontmatter.thumb_img_path && (
                    <Link
                      className="post-thumbnail"
                      to={safePrefix(_.get(post, "url"))}
                    >
                      <img
                        className="thumbnail"
                        src={safePrefix(post.frontmatter.thumb_img_path)}
                        alt={post.frontmatter.title}
                      />
                    </Link>
                  )}
                  <div className="post-content">
                    <p>{post.excerpt}</p>
                  </div>
                  <p className="read-more">
                    {post.frontmatter.tags && (
                      <div className="post-tags">
                        {_.map(post.frontmatter.tags, (tag, tag_idx) => (
                          <span className="post-tag">{tag}</span>
                        ))}
                      </div>
                    )}
                    <Link
                      className="read-more-link"
                      to={safePrefix(_.get(post, "url"))}
                    >
                      Keep reading{" "}
                      <span className="icon-arrow-right" aria-hidden="true" />
                    </Link>
                  </p>
                </article>
              ))}
            </div>
          </main>
          <Footer
            {...this.props}
            site={this.props.pageContext.site}
            page={this.props.pageContext}
            img_path={_.get(
              this.props,
              "pageContext.site.siteMetadata.header.bg_img"
            )}
          />
        </div>
      </Layout>
    );
  }
}
